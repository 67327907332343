import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { registrationActions } from 'modules/register';
import { AccountQuery } from 'modules/app';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';

import { useStep } from './useStep';
import { ContentWithDeco, Heading, SocialLogin } from 'modules/app/components';

import * as styles from 'modules/register/styles/Steps.styles';
import { motion } from 'framer-motion';
import { FADE_IN, FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useDispatch } from 'react-redux';
import { CookieForm } from './CookieForm';
import { FormOne } from '../forms';

import { useLocation } from '@reach/router';

interface Props extends StepRouterComponent {
  setIsCookieAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  isCookieAccepted: boolean;
}

export const StepOne: React.FC<Props> = ({
  onChangeStep,
  location,
  companyInviteToken,
  companyClaimToken,
  isCookieAccepted,
  setIsCookieAccepted,
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const onNext = useStep(
    onChangeStep,
    'step-two',
    'step-one',
    registrationActions.addStepOne,
  );
  // since both email and password exist on FormData and RegistrationData,
  // typescript will only take email and password, therefor, it is a valid type

  const setInviteToken = () => {
    if (companyInviteToken) {
      dispatch(
        registrationActions.setCompanyInviteToken({
          companyInviteToken,
        }),
      );
      return;
    }

    if (companyClaimToken) {
      dispatch(
        registrationActions.setCompanyClaimToken({
          companyClaimToken: `${companyClaimToken}${search}`,
        }),
      );
      return;
    }

    dispatch(registrationActions.reset());
    dispatch(
      registrationActions.setCompanyInviteToken({
        companyInviteToken: undefined,
      }),
    );
    dispatch(
      registrationActions.setCompanyClaimToken({
        companyClaimToken: undefined,
      }),
    );
  };

  useEffect(setInviteToken, [companyInviteToken, companyClaimToken]);

  if (!isCookieAccepted) {
    return (
      <ContentWithDeco css={styles.root}>
        <div css={styles.accountLink}>
          <AccountQuery
            type="register"
            companyInviteToken={companyInviteToken}
            companyClaimToken={companyClaimToken}
          />
        </div>

        <CookieForm setIsCookieAccepted={setIsCookieAccepted} />
      </ContentWithDeco>
    );
  }

  return (
    <ContentWithDeco
      css={styles.root}
      footer={
        <motion.div {...FADE_IN} transition={{ delay: 0.3, duration: 0.5 }}>
          <SocialLogin text="shared.social.social_component_register" />
        </motion.div>
      }
    >
      <div css={styles.accountLink}>
        <AccountQuery
          type="register"
          companyInviteToken={companyInviteToken}
          companyClaimToken={companyClaimToken}
        />
      </div>

      <motion.div
        transition={{ delay: 0.1, duration: 0.4 }}
        style={{ opacity: 0 }}
        {...FADE_IN_MOVE_Y_REGULAR}
      >
        <Heading css={styles.titleShort} as="h1" size="fluidXxLarge">
          <FormattedMessage id="register.page.create_account_title" />
        </Heading>

        <Heading css={styles.subtitle} as="h2" size="root">
          <FormattedMessage id="register.page.create_account_subtitle" />
        </Heading>

        <FormOne onValid={onNext} foreignError={location?.state} />
      </motion.div>
    </ContentWithDeco>
  );
};
