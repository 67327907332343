import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';

export const root = css`
  width: 100%;
  max-width: ${spacing(528)};
  border-top: 2px solid hsl(var(--color-textLight-2));
`;

export const darkVariation = css`
  height: 100%;
  background-color: hsl(var(--color-textLight-2));
  border: 1px solid hsl(var(--color-textLight-2));
  padding: ${spacing(60)} ${spacing(32)} ${spacing(80)};

  & > div {
    border-top: 2px solid white;
  }

  h1 {
    color: white;
  }
`;

export const card = css`
  position: relative;
  margin-top: calc(var(--unit) * 5);
`;

export const alertFix = css`
  margin-bottom: calc(var(--unit) * 10);
`;
