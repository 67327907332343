import React from 'react';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';
import { navigate } from 'gatsby-plugin-intl';

import { registrationActions } from '../redux';
import { useStep } from './useStep';
import { Terms } from '../content/Terms';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useRedirectIfCookieDeclined } from '../hooks/useRedirectIfCookieDeclined';

export const StepTwo: React.FC<StepRouterComponent> = ({ onChangeStep }) => {
  useRedirectIfCookieDeclined();

  const { companyInviteToken } = useSelector(
    (state: AppState) => state.registration,
  );

  const onNext = useStep(
    onChangeStep,
    'step-three',
    'step-two',
    registrationActions.addStepTwo,
  );

  const onBack = () => {
    onChangeStep('step-one');

    if (companyInviteToken) {
      navigate(`/register/token/${companyInviteToken}`);

      return;
    }

    navigate('/register');
  };

  return <Terms onBack={onBack} onNext={onNext} />;
};
