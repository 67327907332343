import React, { useState } from 'react';
import { Button, Counter, HttpError, Paragraph } from 'modules/app';
import * as styles from '../styles/FinalStepBox.styles';
import { FormattedMessage } from 'react-intl';
import { useVerificationEmailResend } from '../hooks';

interface Props {
  email: string;
}

export const FinalStepBox: React.FC<Props> = ({ email }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { httpError, isInRequest, onResendEmail } =
    useVerificationEmailResend();

  const handleResendClick = () => {
    if (isDisabled) return;

    onResendEmail(email);
    setIsDisabled(true);
  };

  return (
    <div css={styles.root}>
      <div css={styles.title}>
        <Paragraph size="fluidLarge" fontFamily="primary">
          <FormattedMessage id="register.page.final_step_title" />
        </Paragraph>

        <Paragraph size="fluidLarge" fontFamily="primary">
          {email}.
        </Paragraph>
      </div>

      <Paragraph css={styles.subtitle_one} size="base">
        <FormattedMessage id="register.page.final_step_subtitle.0" />
      </Paragraph>
      <Paragraph css={styles.subtitle_two} size="base">
        <FormattedMessage id="register.page.final_step_subtitle.1" />
      </Paragraph>

      <Button
        buttonType="secondary"
        disabled={isDisabled}
        onClick={handleResendClick}
        isLoading={isInRequest}
        type="submit"
      >
        {isDisabled ? (
          <FormattedMessage
            id="shared.verification.resend_label_timeout"
            values={{
              placeholder: (
                <Counter
                  isDisabled={isDisabled}
                  setIsDisabled={setIsDisabled}
                  duration={60}
                />
              ),
            }}
          />
        ) : (
          <FormattedMessage id="shared.verification.resend_label" />
        )}
      </Button>

      <HttpError error={httpError} styles={styles.errorFix} />
    </div>
  );
};
