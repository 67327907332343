import { css } from '@emotion/react';

export const root = css`
  margin-top: calc(var(--unit) * 40);
  color: white;
`;

export const title = css`
  p:last-child {
    margin-top: calc(var(--unit));
    font-weight: var(--font-weight-bold);
  }
`;

export const subtitle_one = css`
  font-family: var(--font-family-primary);
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 2);
  color: white;
`;

export const subtitle_two = css`
  font-family: var(--font-family-primary);
  margin: 0 0 calc(var(--unit) * 10);
  color: white;
`;

export const errorFix = css`
  margin-top: calc(var(--unit) * 8);
`;
