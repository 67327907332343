import React from 'react';
import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Seo from 'modules/app/components/Seo';
import { PublicRouter } from 'modules/app';

import RegisterTokenRoot from 'modules/register/TokenRoot';
import RegisterRoot from 'modules/register/Root';

const Opening: React.FC<PageProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.register' })} />
      <Router basepath="/:lang/register">
        <PublicRouter
          Component={RegisterTokenRoot}
          path={`token/:companyInviteToken`}
        />

        <PublicRouter
          Component={RegisterTokenRoot}
          path={`claim/:companyClaimToken`}
        />

        <PublicRouter Component={RegisterRoot} path={`/*`} />
      </Router>
    </>
  );
};

export default Opening;
