import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { navigate } from 'gatsby-plugin-intl';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';

import * as finalStepStyles from 'modules/register/styles/FinalStep.styles';
import { FinalStepBox } from './FinalStepBox';
import { useTermsChecked } from '../hooks/useCheckboxChecked';
import { useRedirectIfCookieDeclined } from '../hooks/useRedirectIfCookieDeclined';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';

export const FinalStep: React.FC<StepRouterComponent> = ({ onChangeStep }) => {
  useRedirectIfCookieDeclined();
  useTermsChecked();
  const registration = useSelector((state: AppState) => state.registration);

  useEffect(() => {
    onChangeStep('final-step');
  }, [onChangeStep]);

  useEffect(() => {
    if (
      registration &&
      !registration.registrationCompleted &&
      registration.companyInviteToken
    ) {
      navigate(`/register/${registration.companyInviteToken}`);

      return;
    }

    if (registration && !registration.registrationCompleted) {
      navigate('/register');
    }
  }, [registration]);

  return (
    <div css={finalStepStyles.darkVariation}>
      <motion.div
        {...FADE_IN_MOVE_Y_REGULAR}
        transition={{ duration: 0.4 }}
        css={[finalStepStyles.root]}
      >
        <FinalStepBox email={registration.email} />
      </motion.div>
    </div>
  );
};
