import { FormProvider, useForm } from 'react-hook-form';
import { ClipboardLabel, NumberField } from 'modules/app';
import React from 'react';
import { QRCode } from 'react-qr-svg';
import * as styles from '../styles/Forms.styles';

interface Props {
  email: string;
  isDisabled: boolean;
  onValid: (data: FormFourData) => void;
  qrCode: { secret: string };
}

export interface FormFourData {
  code: string;
  otpSecret: string;
}

function createOauthUrl(email: string, secret: string): string {
  return `otpauth://totp/Roango:${email}?secret=${secret}&issuer=Roango`;
}

export const FormFour: React.FC<Props> = ({
  onValid,
  email,
  isDisabled,
  qrCode,
}) => {
  const methods = useForm<FormFourData>({
    defaultValues: {
      code: '',
    },
  });

  const { handleSubmit } = methods;

  const onConditionMet = async (val: string) => {
    onValid({ code: val, otpSecret: qrCode?.secret });
  };

  return (
    <div css={styles.root}>
      <div css={styles.qrCodeWrapper}>
        <QRCode value={createOauthUrl(email, qrCode.secret)} />
      </div>

      <ClipboardLabel data={qrCode.secret} />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onValid)}>
          <NumberField
            css={styles.numberField}
            name="code"
            onValid={onConditionMet}
            value={''}
            disabled={isDisabled}
            label="register.qr_code_label"
            constraints={{
              required: {
                value: true,
                message: 'register.errors.invalid_qr_code',
              },
              maxLength: {
                value: 6,
                message: 'register.errors.invalid_qr_code',
              },
              minLength: {
                value: 6,
                message: 'register.errors.invalid_qr_code',
              },
            }}
          />
        </form>
      </FormProvider>
    </div>
  );
};
