import React, { useState } from 'react';
import { RouteComponentProps, Router, useParams } from '@reach/router';
import { StepOne } from 'modules/register/steps';
import { AuthBackground } from 'modules/app/components/stepMechanism';
import { Grid, useCookiePolicy } from '../app';

const TokenRoot: React.FC<RouteComponentProps> = () => {
  const [step, setStep] = useState<Step>('step-one');
  const { getCookie } = useCookiePolicy();
  const [isCookieAccepted, setIsCookieAccepted] = useState(
    getCookie() === 'accept',
  );
  const { companyInviteToken, companyClaimToken } = useParams();
  const onChangeStep = (step: Step) => setStep(step);

  return (
    <Grid type="pageTwoCols">
      <AuthBackground step={step} type="register" />

      <Router>
        <StepOne
          isCookieAccepted={isCookieAccepted}
          setIsCookieAccepted={setIsCookieAccepted}
          onChangeStep={onChangeStep}
          companyInviteToken={companyInviteToken}
          companyClaimToken={companyClaimToken}
          path="/"
        />
      </Router>
    </Grid>
  );
};

export default TokenRoot;
