import React, { useState } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { FinalStep, StepOne, StepTwo, StepThree } from 'modules/register/steps';
import { AuthBackground } from 'modules/app/components/stepMechanism';
import {
  BaseCookieConsent,
  CookieConsent,
  Grid,
  useCookiePolicy,
} from 'modules/app';
import { NotFound } from 'modules/notFound';

const Root: React.FC<RouteComponentProps> = () => {
  const [step, setStep] = useState<Step>('step-one');
  const { getCookie } = useCookiePolicy();
  const [isCookieAccepted, setIsCookieAccepted] = useState(
    getCookie() === 'accept' || getCookie() === 'necessary',
  );

  const onChangeStep = (step: Step) => setStep(step);

  return (
    <>
      <BaseCookieConsent>
        <CookieConsent onAction={setIsCookieAccepted} />
      </BaseCookieConsent>

      <Grid type="pageTwoCols">
        <AuthBackground step={step} type="register" />

        <Router>
          <StepOne
            onChangeStep={onChangeStep}
            path="/"
            setIsCookieAccepted={setIsCookieAccepted}
            isCookieAccepted={isCookieAccepted}
          />
          <StepTwo onChangeStep={onChangeStep} path="step-two" />
          <StepThree onChangeStep={onChangeStep} path="step-three" />

          <FinalStep onChangeStep={onChangeStep} path="final-step" />
          <NotFound additionalStyles="sideWrapper" type="contentOnly" default />
        </Router>
      </Grid>
    </>
  );
};

export default Root;
