import React from 'react';
import { navigate } from 'gatsby-plugin-intl';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';
import { BasicInfo } from '../content/BasicInfo';
import { useTermsChecked } from '../hooks/useCheckboxChecked';
import { useRedirectIfCookieDeclined } from '../hooks/useRedirectIfCookieDeclined';
import { useRegister } from '../hooks';

export const StepThree: React.FC<StepRouterComponent> = ({ onChangeStep }) => {
  useRedirectIfCookieDeclined();
  useTermsChecked();

  const { error, isLoading, register } = useRegister(onChangeStep);

  const onBack = () => {
    onChangeStep('step-two');
    navigate('/register/step-two');
  };

  return (
    <BasicInfo
      onBack={onBack}
      onNext={register}
      error={error}
      isLoading={isLoading}
    />
  );
};
